import { ChangeDetectorRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardcardsComponent } from './pages/dashboardcards/dashboardcards.component';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { DashboardModuleRouting } from './dashboard.module.routing';
import { MaterialModule } from '../shared/modules/material/material.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    DashboardModuleRouting,
    MaterialModule,
    LoadingBarModule.forRoot(),
    LoadingBarHttpModule,
    LoadingBarRouterModule
  ],
  entryComponents: [DashboardcardsComponent],
  bootstrap: [DashboardcardsComponent],
  declarations: [DashboardcardsComponent],
  exports: []
})
export class DashboardModule { }
