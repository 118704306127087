import { Component } from '@angular/core';
import { PermissionStore} from './stores/permissions.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(private permissionStore:PermissionStore){
  this.permissionStore.composePermissions();
  }

}
