import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../obj/userObj';

@Injectable({
    providedIn: 'root',

})

export class LoginService {



    protected standardHeaders() {
        return {
            'Content-Type': 'application/json',
            'Token': '12345',
        };
    }


    constructor(private http: HttpClient) { }

    login(data){
        return this.http.post(environment.api_url + '/user/login', data, {
            headers: this.standardHeaders()
        });
    }

    //  token validation for set password in user Email
    checkValidityOfToken(token: any): Observable<any> {
        return this.http.get(environment.api_url + '/user/checkToken?token=' + token, {
            headers: this.standardHeaders()
        });
    }
   // set password API
UpdatePassword(user,token){
    return this.http.post(environment.api_url+'/user/setPassword?token='+token,user,{
        headers:this.standardHeaders()
    })

}
   // forget  password API
    checkMailValidator(emailId: any):Observable<any>{
        return this.http.get(environment.api_url+'/user/forgotPasswordEmail?emailId='+emailId,{
            headers:this.standardHeaders()
        })
    }





}