import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { UserModule } from './user-module/user.module';
import { LoginComponent } from './user-module/pages/login/login.component';
import { DashboardComponent } from './dashboardmodule/dashboard/dashboard.component';
import { DashboardModule } from './dashboardmodule/dashboard.module';
import { RoleModule } from './role/role.module';
import { UserDashboardComponent } from './user-dashboard/user-dashboard/user-dashboard.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { ForgetPasswordComponent } from './forgetPassword/forgetPassword.component';
import { setPasswordComponent } from './setPassword/setPassword.component';
const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgetPassword',
        component: ForgetPasswordComponent
    },
    {
        path: 'setPassword',
        component: setPasswordComponent
    },
    {   
        path: 'admin',
        component: DashboardComponent,
    //    canActivate: [AuthGuard],
        children: [{
            path: 'dashboard',
            loadChildren: 'app/dashboardmodule/dashboard.module#DashboardModule',
        },
        {
            path: 'role',
            loadChildren: 'app/role/role.module#RoleModule'
        },
        {
            path: 'category',
            loadChildren: 'app/category/category.module#CategoryModule'
        },
        {
            path: 'organisation',
            loadChildren: 'app/organisation/organisation.module#OrganisationModule'
        },
        {
            path: 'lesson',
            loadChildren: 'app/lesson/lesson.module#LessonModule'
        },
        {
            path: 'course',
            loadChildren: 'app/course/course.module#CourseModule'
        },
        {
            path: 'user',
            loadChildren: 'app/user-module/user.module#UserModule'
        },
        {
            path: 'video',
            loadChildren: 'app/video/video.module#VideoModule'
        },
        {
            path: 'profile',
            loadChildren: 'app/profile/profile.module#ProfileModule'
        },
        ]
    },
    {
        path: 'user',
        component: UserDashboardComponent,
        canActivate: [AuthGuard],
        children: [{
            path: 'dashboard',
            loadChildren: 'app/user-dashboard/user-dashboard.module#UserDashboardModule'

        },
        {
            path: 'course',
            loadChildren: 'app/user-course/user-course.module#UserCourseModule'
        },
        {
            path: 'category',
            loadChildren: 'app/user-category/user-category.module#UserCategoryModule'
        },
        {
            path: 'recentlyViewed',
            loadChildren: 'app/user-recentlyViewed/user-recentlyViewed.module#UserRecentlyViewedModule'
        },
        {
            path: 'lesson',
            loadChildren: 'app/user-lesson/user-lesson.module#UserLessonModule'
        },
        {
            path: 'user',
            loadChildren: 'app/userpanel-user/userpanel-user.module#UserPanelUserModule'
        },
        {
            path: 'role',
            loadChildren: 'app/user-dashbord-roles/role.module#UserDashboardRoleModule'
        },
        {
            path: 'userProfile',
            loadChildren: 'app/user-profile/userProfile.module#UserProfileModule'
        },
        ]
    },


];
@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash:true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
