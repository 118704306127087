import {observable,computed,action } from 'mobx-angular';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn:'root',
})
export class PermissionStore {
    //for role permission
    @observable creatRolePermission:boolean=false;
    @observable editRolePermission:boolean=false;
    @observable deleteRolePermission:boolean=false;
    @observable readRolePermission:boolean=false;
    //for user permission
    @observable creatUserPermission:boolean=false;
    @observable editUserPermission:boolean=false;
    @observable deleteUserPermission:boolean=false;
    @observable readUserPermission:boolean=false;

    //for category permission
    @observable creatCategoryPermission:boolean=false;
    @observable editCategoryPermission:boolean=false;
    @observable deleteCategoryPermission:boolean=false;

    //for Course permission
    @observable creatCoursePermission:boolean=false;
    @observable editCoursePermission:boolean=false;
    @observable deleteCoursePermission:boolean=false;
    //for lesson permission
    @observable creatLessonPermission:boolean=false;
    @observable editLessonPermission:boolean=false;
    @observable deleteLessonPermission:boolean=false;

   @action
    composePermissions():void{
    var obj = JSON.parse(localStorage.getItem('userObject'));
    if(obj!=null && obj!=undefined)
    {
    if (obj.roles.length > 0) {
        for (let rolePermission of obj.roles[0].applicationRolePermission) {
          if (rolePermission.component.componentId == 2) {
            this.creatRolePermission=rolePermission.isWrite;
            this.editRolePermission=rolePermission.isUpdate;
            this.deleteRolePermission=rolePermission.isDelete;
            this.readRolePermission=rolePermission.isRead;
          }

          if (rolePermission.component.componentId == 3) {
            this.readUserPermission=rolePermission.isRead;
            this.creatUserPermission=rolePermission.isWrite;
            this.editUserPermission=rolePermission.isUpdate;
            this.deleteUserPermission=rolePermission.isDelete;
          }

          if (rolePermission.component.componentId == 4) {
            this.creatCategoryPermission=rolePermission.isWrite;
            this.editCategoryPermission=rolePermission.isUpdate;
            this.deleteCategoryPermission=rolePermission.isDelete;
          }

          if (rolePermission.component.componentId == 5) {
            this.creatCoursePermission=rolePermission.isWrite;
            this.editCoursePermission=rolePermission.isUpdate;
            this.deleteCoursePermission=rolePermission.isDelete;
          }

          if (rolePermission.component.componentId == 6) {
            this.creatLessonPermission=rolePermission.isWrite;
            this.editLessonPermission=rolePermission.isUpdate;
            this.deleteLessonPermission=rolePermission.isDelete;
          }

        }
      }

   }


  }

  }

