import { ChangeDetectorRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardcardsComponent } from './dashboardcards/dashboardcards.component';
import {MaterialModule} from '../shared/modules/material/material.module';
//import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import {UserDashboardRoutingModule} from './user-dashboard-routing.module';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DeleteCategoryModule } from '../category/delete-category/delete-category.module';
import { DeleteCategoryComponent } from '../category/delete-category/delete-category.component';
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
  
    MaterialModule, 
    FormsModule,
    LoadingBarModule.forRoot(),
    LoadingBarHttpModule,
    LoadingBarRouterModule,
    UserDashboardRoutingModule,
    DeleteCategoryModule
    
  ],
  declarations: [DashboardcardsComponent],
  bootstrap: [DashboardcardsComponent],
  entryComponents: [DeleteCategoryComponent, DashboardcardsComponent],
})
export class UserDashboardModule { }


