import { Component, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-dashboardcards',
  templateUrl: './dashboardcards.component.html',
  styleUrls: ['./dashboardcards.component.scss']
})
export class DashboardcardsComponent implements OnInit {

  constructor(public loader: LoadingBarService) { }

  ngOnInit() {
  }

}
