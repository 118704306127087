import { Component, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {CategoryService} from '../../services/category.service';
import {Category} from '../../obj/categoryObj';
import {Router,NavigationEnd} from '@angular/router';
import { LessonService } from '../../services/lesson.service';
import { Lesson } from '../../obj/lessonObj';
import { ApplicationRolePermission } from '../../obj/applicationRolePermission';
import { MatDialog } from '@angular/material';
import { DeleteCategoryComponent } from '../../category/delete-category/delete-category.component';
import { PermissionStore } from '../../stores/permissions.store';
import {StorageService} from '../../services/storage.service';
@Component({
  selector: 'app-dashboardcards',
  templateUrl: './dashboardcards.component.html',
  styleUrls: ['./dashboardcards.component.scss']
})
export class DashboardcardsComponent implements OnInit {
  obj;
  rolePermission: ApplicationRolePermission[]=[];
  Categorypermission: ApplicationRolePermission[]=[];
  categories: Category[] = [];
  recentviews:any;
  analtic: any;
  category: Category = new Category();
  categoryId=0;
  loading:boolean=false;
  constructor(public loader: LoadingBarService, public dialog: MatDialog,
    private router: Router,
    private categoryService: CategoryService,
    private permissionStore:PermissionStore,
    private storageService: StorageService,
    private lessonService: LessonService) {
      this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          this.router.navigated = false;
          window.scrollTo(0, 0);
        }
      });
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
     }

  ngOnInit() {
    
    this.loading=true;
    this.obj = JSON.parse(localStorage.userObject);
    this.categoryService.getUserCategoryList(this.obj).subscribe(response => {
        this.categories = response;
        this.loading=false;
        console.log(this.categories)
        this.storageService.setAllcategories(this.categories);
        
    });
  
    this.getAllRecentlyAddedVideos();
    this.getPermission();
}


showPercentage() {
  let role = this.obj.roles[0];
  let count=0;
    for(let i = 0; i < role.applicationRolePermission.length; i++){
      if(role.applicationRolePermission[i].component.code == "COURSE" || 
      role.applicationRolePermission[i].component.code == "TOPIC" ||
       role.applicationRolePermission[i].component.code == "SECTION"){
        if(role.applicationRolePermission[i].isRead == true && 
          (role.applicationRolePermission[i].isUpdate == false &&
            role.applicationRolePermission[i].isWrite == false &&
            role.applicationRolePermission[i].isDelete == false)) {
              count = count+1;
            } }  }
            if(count == 3){
              return true;
            } else {
            return false;
            }
}


getAllRecentlyAddedVideos() {
  this.obj = JSON.parse(localStorage.userObject);
  var organisationId = this.obj.organisation.organisationId;
  var transactions = {
    "organisationId": organisationId,
    "userId": this.obj.userId,
  }

  this.lessonService.getAllRecentlyAddedVideos(transactions).subscribe(response => {
    this.recentviews = response;
    console.log(this.recentviews);
  });
}
getPermission(){
if(this.obj.roles.length>0){
this.rolePermission=this.obj.roles[0].applicationRolePermission
for(let rolePermission of this.rolePermission){
  if(rolePermission.component.componentId == 4  )
  { 
this.Categorypermission.push(rolePermission);
  }
}
if(this.Categorypermission[0]!=undefined){
}
}
}
 openDialog(categoryId): void {
  const dialogRef = this.dialog.open(DeleteCategoryComponent, {
    width: '975px',
     data: { categoryId: categoryId }
  });
}
goToLesson(recordId,recordName){
  
    let obj1 = {
      "lessonName":this.recentviews.recordName,
      "lessonId":this.recentviews.recordId,
      "recentlyViewed":true
    }
    this.router.navigate(['user/lesson/browse'],{queryParams:obj1})
  }

  categoryLaunch(categoryName,categoryId){
    let obj1 = {
      "categoryName":categoryName,
      "categoryId":categoryId,
      "categoryComponent":true
    }
    this.router.navigate(['user/lesson/browse'],{queryParams:obj1})
  }

  categoryNavigate(category){
    
   if(category.fileType!=null){
    localStorage.setItem('selectedCategory',JSON.stringify(category));
    this.storageService.setSelectedCategory(category);
    this.categoryLaunch(category.name,category.categoryId);
   }
   else{
    this.saveCategoryInLocal(category);
   }
  }

  saveCategoryInLocal(category){
    localStorage.setItem('selectedCategory',JSON.stringify(category));
    this.storageService.setSelectedCategory(category);
    this.router.navigate(['/user/category/browse'], {queryParams:{'categoryId': category.categoryId, 'categoryName': category.name}})
  }

}
