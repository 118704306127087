import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../shared/modules/material/material.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import {CoreModuleRouting} from './core-module.routing';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModuleRouting,
    LoadingBarModule.forRoot(),
    LoadingBarHttpModule,
    LoadingBarRouterModule
  ],
  declarations: [FooterComponent, HeaderComponent],
  exports : [FooterComponent, HeaderComponent]

})
export class CoreModule { }
