// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.35.202.253.105

export const environment = {
  production: false,
  // api_url: 'http://pre-prod.hbfxlabs.com/core/api/v1/hbfx-insta-authentication',  // user ,organisations, roles
  // api_url1: 'http://pre-prod.hbfxlabs.com/core/api/v1/hbfx-cnt-elms',       /// Category , and course,lesson

  imageSize: 1000000,
  videoSize: 700000000,

 // //enable this code before deploying on TEST server

  // new_login_url_local:'http://localhost:4200',
  // new_login_url_live:'http://elmsnew.hbfxlabs.com',

  // new_user_dashboard_url_local:'http://localhost:4200/#/user/dashboard/browse',
  // new_user_dashboard_url_live:'http://elmsnew.hbfxlabs.com/#/user/dashboard/browse',

  //enable this code before deploying on LIVE server

   api_url: 'https://elms.hornbillfx.com/core/api/v1/hbfx-insta-authentication', 
   api_url1: 'https://elms.hornbillfx.com/core/api/v1/hbfx-cnt-elms',

  new_login_url_local:'http://localhost:4200',
  new_login_url_live:'https://elms.hornbillfx.com',

  new_user_dashboard_url_local:'http://localhost:4200/#/user/dashboard/browse',
  new_user_dashboard_url_live:'https://elms.hornbillfx.com/#/user/dashboard/browse',

};
