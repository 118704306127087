import { Organisation } from "./organisationObj";
import { User } from "./userObj";




export class Category {

  name:string;
  description: string;
  isActive: boolean;
  categoryId: number;
  categoryParent:CategoryParent;
  organisation: Organisation;
  createdOn:Date;
  createdBy:number;
  fileType:string;
  launchUrl:any;
  fileName:string;
  // updatedOn:string;
  // updatedBy:number;
  
  // dropdrown filter on list page 30-06-18 thiru
  organisationname:string;
  organisationParentname:string;
       
}

export class CategoryParent {
  categoryParentId:number;
}

export class AssignCategory{
  organisationId:Organisation;
  categoryId:Category;
  userId:User;
  isActive:boolean=true;
  createdBy:number=1;
  createdOn="2018-06-11";
  lastAccessed="2018-06-11";
  updatedOn:string;
  updatedBy:number=1;
   }

  

