import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { User } from '../../../obj/userObj';
import { MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Jsonp } from '@angular/http';
import { PermissionStore} from '../../../../app/stores/permissions.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, public snackBar: MatSnackBar, private loginservice: LoginService,
  private permissionStore:PermissionStore) { }
  loginData;
  user: User = new User();
  rememberMe:boolean=false;
  passwordType:boolean=false;
  text;

  ngOnInit() {
    this.user.emailId=sessionStorage.emailId;
    this.user.passwordHash=sessionStorage.password;
  }

  login() {
    this.loginservice.login(this.user).subscribe((response) => {
      this.loginData = response;
      if (this.loginData.status.success != 'Success') {
        this.snackBar.open('', 'Invalid Credentials', {
          duration: 2000,
        });

      } else { 
        this.router.navigate(['user/dashboard/main']);
        localStorage.userObject = JSON.stringify(this.loginData.data);
        localStorage.userName = this.loginData.data.userName;
        localStorage.email = this.loginData.data.emailId;
        localStorage.isLogined = true;
        this.permissionStore.composePermissions();
        if(this.rememberMe==true){
          sessionStorage.emailId=localStorage.email;
          sessionStorage.password=this.loginData.data.passwordHash;
         }
      }
    },
      error => {
        this.snackBar.open('', 'Invalid Credentials', {
          duration: 2000,
        });
      
    });
  }
}
