import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private categories:any [];
  private selectedCategory:any;
  constructor() { }

  setAllcategories(categories) {
    console.log(categories);
    this.categories = categories;
  }
  getAllCategories(){
    console.log(this.categories);
    return this.categories
  }

  setSelectedCategory(category){
    this.selectedCategory = category;
  }
  getSelectedCategory() {
    console.log(this.selectedCategory);
    return this.selectedCategory;
  }
}

