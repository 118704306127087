import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CategoryInfoComponent } from '../category-info/category-info.component';
import { Location } from '@angular/common';
import { CategoryService } from '../../services/category.service';
import { Category } from '../../obj/categoryObj';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-category',
  templateUrl: './delete-category.component.html',
  styleUrls: ['./delete-category.component.scss']
})
export class DeleteCategoryComponent implements OnInit {
  categoryId = '';
  checkBoxForDelete = [];
  categoryForDeletePayload: Category = new Category();
  iscatActive: any;
  noOfItems;
  categorys: Category[] = [];
  tempcheckBoxForDelete: any[]=[];
  fromAdminPanel:Boolean=false;

  constructor(private categoryService: CategoryService, private router: Router, public snackBar: MatSnackBar, private _location: Location,
    public dialogRef: MatDialogRef<CategoryInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
    this.categoryId = this.data.categoryId;
    this.iscatActive = this.data.isActive;
    this.fromAdminPanel=this.data.fromAdminPanel;
    this.checkBoxForDelete = this.data.numSelected;
    this.noOfItems = this.checkBoxForDelete;
    if (this.noOfItems == undefined || this.noOfItems == null) {
      this.categoryService.getCategoryById(this.categoryId, this.iscatActive).subscribe((response) => {
        this.categoryForDeletePayload = response.data;
      });
    } else {
      this.categoryForDeletePayload = null;
    }
  }

  delete() {
    if (this.categoryForDeletePayload != null) {
      this.categoryForDeletePayload.isActive = false;
      this.categoryService.categoryFileToStorage( null, null,this.categoryForDeletePayload).subscribe((response) => {
        this.snackBar.open(this.categoryForDeletePayload.name, 'Deleted', {
          duration: 750,
        });
        this.dialogRef.close();
        //accotding to admin user it will navigate
        if(this.fromAdminPanel==true){
          this.router.navigate(['/admin/category/browse']);
         }else{
          this.router.navigate(['/user/dashboard/main']);
         }
      });
    }
    else {
      for (let i = 0; i < this.checkBoxForDelete.length; i++) {
        let catagory = {
          categoryId: this.checkBoxForDelete[i].categoryId
        }
        this.tempcheckBoxForDelete.push(catagory);
      }
      this.categoryService.bulkDeleteCategory(this.checkBoxForDelete).subscribe(response => {
        this.snackBar.open(this.noOfItems.length  + ' COURSES', 'Deleted', {
          duration: 750,
        });
        this.dialogRef.close();
        //accotding to admin user it will navigate
        if(this.fromAdminPanel==true){
          this.router.navigate(['/admin/category/browse']);
         }else{
          this.router.navigate(['/user/dashboard/main']);
         }
      });
     
    }
  }

  backClicked() {
    this._location.back();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

