import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/modules/material/material.module';

import { DeleteCategoryComponent } from './delete-category.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  declarations: [DeleteCategoryComponent],
  exports: [DeleteCategoryComponent],

})
export class DeleteCategoryModule { }
