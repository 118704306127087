import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Organisation } from '../obj/organisationObj';

@Injectable({

    providedIn: 'root'

})


export class CategoryService {


    constructor(private Http: HttpClient) { }

    protected standardHeaders() {

        return {

            'Content-Type': 'application/json',
            'token': '12345'
        };

    }
   //  no need to hit this /category/list anymore bcos to speedup the performance -17|07|18
  //to get isActive based categorylist 11-07-18
  getIsActivebasedCateogryList(isActive): Observable<any> {

     // return this.Http.post(environment.api_url1+'/category/list', {"isActive":isActive}, {
        return this.Http.post(environment.api_url1+'/category/adminViewList', {"isActive":isActive}, {
        headers: this.standardHeaders()
    });
}

    //to list all category
    getCategoryList(): Observable<any> {
        return this.Http.post(environment.api_url1 + '/category/list', {}, {  
            headers: this.standardHeaders()
        })

    }
    //to list only on the view use this
    getCategoryviewList(): Observable<any> {
        //  return this.Http.post(environment.api_url1 + '/category/list', {}, {  
          return this.Http.post(environment.api_url1 + '/category/adminViewList', {}, {
              headers: this.standardHeaders()
          })
  
      }
getCategoryListBasedonOrgId(organisationId):Observable<any>{
    return  this.Http.post(environment.api_url1 + '/category/paramOrgList', {"organisation":{organisationId}}, {
        headers: this.standardHeaders()
    })
}
//saving,editing and delete we use same payload
    save(data: any): Observable<any> {

        return this.Http.post(environment.api_url1 + '/category', data, {
            headers: this.standardHeaders()

        });
    }
    updateCategory(data: any) {
        return this.Http.post(environment.api_url1 + '/category', data, {
            headers: this.standardHeaders()
        })
    }


    delete(data) {

        return this.Http.post(environment.api_url1 + '/category', data, {

            headers: this.standardHeaders()
        })

    }
    //for getting datas in edit
    getCategoryById(CategoryId:any, isActive): Observable<any> {
        return this.Http.post(environment.api_url1 + '/category/params', { "categoryId": CategoryId ,'isActive':isActive }, {
            headers: this.standardHeaders()
        });

    }

    //// this categoryparentList
    getcatparentlist(): Observable<any> {

        return this.Http.post(environment.api_url1 + '/CategoryParent/list', {}, {
            headers: this.standardHeaders()
        })
    }

    //assign user category
  getUserCategoryList(userId): Observable<any> {
        return this.Http.post(environment.api_url1 + '/userAssignedCategory/list',userId, {
            headers: this.standardHeaders()
        });
    }


/// bulk dalete for all category component 16:07:18
bulkDeleteCategory(categoryIds) :Observable<any> {
    
    return this.Http.post(environment.api_url1+'/category/batchDelete',categoryIds, {  headers: this.standardHeaders() });
 
 }
 // upload for category level 20:08:2018 thiru

 categoryFileToStorage(file: File, image:File,category: any): Observable<HttpEvent<{}>> {
    let formdata: FormData = new FormData();

    formdata.append('file', file);
    formdata.append('image', image);
    formdata.append('categoryObject', JSON.stringify(category));

    const req = new HttpRequest('POST', environment.api_url1 + '/category', formdata, {
        reportProgress: true,
        // responseType: 'text'
    });

    return this.Http.request(req);
}


  // userForAll SelectedCategoryId thiru 21:08:2018
userForAllSelectedCategoryId(selectedOrganisationId:any,selectedCategoryId:any){

    return this.Http.post(environment.api_url1+ '/categoryUserList' ,{'organisationId':selectedOrganisationId ,'categoryId': selectedCategoryId } ,{
        headers: this.standardHeaders()
    })
}
  // Assing course userFor SelectedUsers thiru 21:08:2018

assignCategoryForUser(organisationId,categoryId,data:any){
    return this.Http.post(environment.api_url1 +'/categoryUser/'+ categoryId +'?orgId=' + organisationId , data, {
        headers: this.standardHeaders()

    })
}

categoryLaunchPath(userobj:any, categoryId:any): Observable<any>{
    return this.Http.post(environment.api_url1 
        + '/category/launch?categoryId='+ categoryId, userobj,
        { headers: this.standardHeaders() });
        
}
    
}
