import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
 import { HttpModule } from '@angular/http';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router) {}
  canActivate(): boolean {
    var login=localStorage.getItem('isLogined');
    if (login!='true') {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}