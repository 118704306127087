
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { headersToString } from 'selenium-webdriver/http';



@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {

    }

    protected standardHeaders() {
        return {
            'Content-Type': 'application/json',
            'token': '12345',
        };
        }
//to get isActive based userlist 11-07-18
getIsActivebasedUserList(isActive): Observable<any> {
    return this.http.post(environment.api_url+'/user/adminViewList', {"isActive":isActive}, {
        headers: this.standardHeaders()
        
    });
  }
        
 // to increase speed for get list os UI side listpage 24:07:18
 getUserAdminViewList(): Observable<any>{
    return this.http.post(environment.api_url+'/user/adminViewList', {}, {
        headers: this.standardHeaders()
    });
}
     getUserList(): Observable<any> {
        return this.http.post(environment.api_url + '/user/list', {}, {
            headers: this.standardHeaders()
        });
    }
    getInActiveUserList(isActive): Observable<any> {
        return this.http.post(environment.api_url+'/user/list', {"isActive":isActive}, {
            headers: this.standardHeaders()
            
        });
      }
    // To show  child organisation based userlist on userpanel 26:07:18
    getUserListBasedOrgId(orgId): Observable<any> {
        return this.http.post(environment.api_url + '/user/userViewList?orgId='+orgId, {}, {
            headers: this.standardHeaders()
        });
    }
    saveUser(data: any):Observable<any> {

        return this.http.post(environment.api_url + '/user', data, { headers: this.standardHeaders() });

    }
    editUser(data: any):Observable<any> {
        return this.http.post(environment.api_url + '/user', data, { headers: this.standardHeaders() });
    }

    getUserId(userId: any , isActive): Observable<any> {
        return this.http.post(environment.api_url + '/user/params', { "userId": userId ,'isActive':isActive }, { headers: this.standardHeaders() });
    }
    deleteUser(userId: any): Observable<any> {
        return this.http.post(environment.api_url + '/user', userId, { headers: this.standardHeaders() });
    }

    getProfile(): Observable<any> {
        return this.http.post(environment.api_url + '/user/params', { "userId": JSON.parse(localStorage.userObject).userId }, { headers: this.standardHeaders() });
    }
    getAllUsersBasedOnOrgId(orgId: any): Observable<any> {
        return this.http.get(environment.api_url+'/usersBasedOnOrgId/list?orgId='+orgId,{ headers: this.standardHeaders() })
    }
///user Batch delete 
BatchDeleteForUser(userIds:any):Observable<any>{
    return this.http.post(environment.api_url+'/user/batchDelete',userIds,{
        headers:this.standardHeaders()

    });
}
// Analytic For UserAdmin 
AnalyticForUserAdmin(data:any ,categoryId:any):Observable<any>{
    return this.http.post(environment.api_url1+'/userAssignedCategoryForReport/list?categoryId='+categoryId,data,{
         headers:this.standardHeaders() });
        
}

getParticularUserCategoryList(data:any):Observable<any>{
    return this.http.post(environment.api_url1+'/categoriesAssignedToUser/catalog',data,{
         headers:this.standardHeaders() });
        
}


}
