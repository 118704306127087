import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule, MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule,
  MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule,
  MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonModule, MatButtonToggleModule, MatChipsModule, MatIconModule,
  MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatTooltipModule, MatSnackBarModule, MatTableModule,
  MatSortModule, MatPaginatorModule, MatNativeDateModule, MatRippleModule
} from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule, MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule,
    MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule,
    MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonModule, MatButtonToggleModule, MatChipsModule, MatIconModule,
    MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatTooltipModule, MatSnackBarModule, MatTableModule,
    MatSortModule, MatPaginatorModule, MatNativeDateModule, MatRippleModule],
  exports: [MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule, MatInputModule, MatRadioModule,
    MatSelectModule, MatSliderModule, MatSlideToggleModule, MatMenuModule,
    MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule,
    MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonModule, MatButtonToggleModule, MatChipsModule, MatIconModule,
    MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatTooltipModule, MatSnackBarModule, MatTableModule,
    MatSortModule, MatPaginatorModule, MatNativeDateModule, MatRippleModule],
  declarations: []
})
export class MaterialModule { }
