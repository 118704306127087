import { Role } from './roleObj';
import { Organisation } from './organisationObj';
export class User {
 //   fullName: string;
    userName: string;
    firstName: string;
    lastName: string;
    emailId: string;
    passwordHash: string;
    organisation: Organisation;
    isActive: boolean;
    createdBy: number;
    createdOn: Date;
    roles: Role[];
    userDetails: UserDetails[];
    city: string;
    country: string;
    description: string;
    userId: any;
    
     // dropdrown filter on list page 30-06-18 thiru
     organisationname:string;
     organisationParentname:string;
}

export class UserDetails {
    mobileNumber: string;
    phoneNumber: string;
    address: object;
    isActive: boolean;
    createdBy: number;
    createdOn: Date;
}