import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import {environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  userId: any;
  userEmail: any;
  loginData: any;
  presentEnvironment='';
  constructor(changeDetectorRef: ChangeDetectorRef, private router: Router,
    private activatedRoute:ActivatedRoute, private userService:UserService,
    media: MediaMatcher, public loader: LoadingBarService) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.userId = params['userId'];
      // this.userEmail= params['userpwd'];
    });
    console.log("present env is..........." + window.location.href)
    var windowlocation = window.location.href;
    if (windowlocation.includes("localhost")) {
      this.presentEnvironment = "local";
    }
    else{
      this.presentEnvironment="live";
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
 
    this.userService.getUserId(this.userId,true).subscribe((response) => {
      this.loginData = response;
      localStorage.userObject = JSON.stringify(this.loginData.data);
      localStorage.userName = this.loginData.data.userName;
      localStorage.email = this.loginData.data.emailId;
      let userID =this.loginData.data.userId;


      console.log(this.loginData);
    });
  }
  logout(){
    localStorage.clear();
   
    if(this.presentEnvironment=='local'){
      window.location.href = environment.new_login_url_local;
    }
    else{
      window.location.href = environment.new_login_url_live;
    }
  }
userpanel(){
  if(this.presentEnvironment=='local'){
    window.location.href = environment.new_user_dashboard_url_local;
  }
  else{
    window.location.href = environment.new_user_dashboard_url_live;
  }
}

}
