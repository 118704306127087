import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {AppRoutingModule} from './app.routing';
import {RouterModule} from '@angular/router';
import {CoreModule} from './core/core.module';
import {MaterialModule} from './shared/modules/material/material.module';
import { LoginComponent } from './user-module/pages/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DashboardComponent} from './dashboardmodule/dashboard/dashboard.component';
import {DashboardModule} from './dashboardmodule/dashboard.module';
import {UserDashboardModule} from './user-dashboard/user-dashboard.module';

import { SidebarComponent } from './dashboardmodule/sidebar/sidebar.component';
import {MatButtonModule, MatCheckboxModule, MatCardModule} from '@angular/material';
import { HttpClientModule} from '@angular/common/http';
import { HttpModule} from '@angular/http';

import {  UserService } from './services/user.service';
import {  RoleService } from './services/role.service';
import {  CourseService } from './services/course.service';
import {  ComponentService } from './services/component.service';
import {OrganisationService } from './services/organisation.service';
import { FormsModule } from '@angular/forms';
import { UserDashboardComponent } from './user-dashboard/user-dashboard/user-dashboard.component';
import { AuthGuardService  } from './auth/auth-guard.service';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { ForgetPasswordComponent } from './forgetPassword/forgetPassword.component';
import { setPasswordComponent } from './setPassword/setPassword.component';
import { MobxAngularModule } from 'mobx-angular';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    UserDashboardComponent,
    SidebarComponent,
    LoginComponent,
    ForgetPasswordComponent,
    setPasswordComponent
    // PhoneNumberValidation
    ],
    
  imports: [
    BrowserModule,
    RouterModule,
    DashboardModule,
    AppRoutingModule,
    UserDashboardModule,
    CoreModule,
    MatCardModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    MobxAngularModule
  
  ],
  providers: [
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
