
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router } from '@angular/router';
import { json } from 'd3';
import { ApplicationRolePermission } from '../../obj/applicationRolePermission';
import { PermissionStore } from '../../stores/permissions.store';
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {
  mobileQuery: MediaQueryList;
  obj;
  isAdmin;
  isHaveUserPermission: boolean = false;
  isHaveRolePermission: boolean = false;
  userPermissionObj: any;
  rolePermissionObj: any;
  rolePermission: ApplicationRolePermission[] = [];
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, private router: Router,
    private permissionStore: PermissionStore,
    media: MediaMatcher, public loader: LoadingBarService) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.obj = JSON.parse(localStorage.getItem('userObject'));
   // this.isAdmin=JSON.parse(localStorage.userObject).isAdmin;
   this.isAdmin=this.obj.isAdmin;
   this.setPermissionForComponents();
  }
  edit(){
    if(JSON.parse(localStorage.userObject).isAdmin==true){
      this.router.navigate(['/admin/profile/browse']);
     }else{
      this.router.navigate(['/user/userProfile/browse']);
     }
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngOnInit() {
  }
  logout() {
    localStorage.clear();
  }

    
  setPermissionForComponents() {
    if( this.permissionStore.deleteUserPermission|| this.permissionStore.editUserPermission||this.permissionStore.creatUserPermission ||
      this.permissionStore.readUserPermission){
      this.isHaveUserPermission=true;
      }
    if (this.permissionStore.deleteRolePermission || this.permissionStore.editRolePermission || 
      this.permissionStore.creatRolePermission || this.permissionStore.readRolePermission) {
      this.isHaveRolePermission = true;
    }
  }
}
