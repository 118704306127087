import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { User } from '../obj/userObj';
import { FormControl, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';


@Component({
  selector: 'app-forgetPassword',
  templateUrl: './forgetPassword.component.html',
  styleUrls: ['./forgetPassword.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  emailId: any;
  status: any;
  errormessage: any;
  
  constructor(private router: Router, private loginService: LoginService, public snackBar: MatSnackBar, ) { }
  loginData;
  emailError: boolean = false;
  error: boolean = false;
  user: User = new User();
  rememberMe: boolean = false;

  ngOnInit() {
    this.user.emailId = sessionStorage.emailId;
  }

  sendEmail(emailId) {
    debugger
    this.loginService.checkMailValidator(emailId).subscribe(response => {
      console.log(response);
      if(response.status.sucess!='success'){
        this.snackBar.open(response.status, 'Please Check your Email', {
          duration: 2000,
        });
      this.router.navigate(['/login'])
        this.emailError=false;
      }
     },error=>{
       this.emailError=true;
     })
  }

}