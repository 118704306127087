import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { User } from '../obj/userObj';
import { FormControl, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-setPassword',
  templateUrl: './setPassword.component.html',
  styleUrls: ['./setPassword.component.scss']
})
export class setPasswordComponent implements OnInit {
  passwordHash1: any;
  passwordHash2: any;
  passwordError: boolean=false;
  passwordType:boolean=false;
  text;
  constructor(private router: Router, private loginService: LoginService, private setpassword: LoginService, private route: ActivatedRoute, public snackBar: MatSnackBar, ) {

  }
  loginData;
  user: User = new User();
  rememberMe: boolean = false;
  tokens;
  flag: boolean = true;
  ngOnInit() {
    this.user.passwordHash = sessionStorage.password;
    this.route.queryParams.subscribe((params: Params) => {
      this.tokens = params['token']
    })
    this.checkValidityOfToken();

  }
  checkValidityOfToken() {
    this.setpassword.checkValidityOfToken(this.tokens).subscribe(data => {
      this.user = data;
      if (this.user.userId == null) {
        this.flag = false
      }
    }, error => {

      this.flag = false;
    })
  }

  setPassword(user: User) {
    if (this.passwordHash2 !== this.passwordHash1) {
      this.passwordError=true;
    }
    else {
      //  user.passwordHash=this.passwordHash1;
      this.user.passwordHash = this.passwordHash1;
      this.loginService.UpdatePassword(this.user, this.tokens).subscribe((response) => {
        this.snackBar.open('', 'Updated Password Successfully', {
          duration: 2000,
        });
        this.router.navigate(['/login']);
      });
    }
  }

}


