import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({

    providedIn: 'root'

})


export class LessonService {

    constructor(private Http: HttpClient) { }


    protected standardHeaders() {

        return {

            'Content-Type': 'application/json',
            'token': '12345',
        }
    }
///lesson/list--> no need to use in getIsActivebasedLessonList() and getLessonList()
    //to get isActive based lessonlist 11-07-18
getIsActivebasedLessonList(isActive): Observable<any> {

    return this.Http.post(environment.api_url1+'/lesson/adminViewList', {"isActive":isActive}, {
        headers: this.standardHeaders()
    });
  }

  //to get list on info
    getLessonViewList(): Observable<any> {

        return this.Http.post(environment.api_url1 + '/lesson/adminViewList', {}, {
            headers: this.standardHeaders()

        })

    }

    getLessonList(): Observable<any> {

        return this.Http.post(environment.api_url1 + '/lesson/list', {}, {
            headers: this.standardHeaders()

        })

    }


    save(data: any) {
        return this.Http.post(environment.api_url1 + '/lesson', data, {

            headers: this.standardHeaders()

        })

    }

    getLessonById(lessonId: any , isActive): Observable<any> {

        return this.Http.post(environment.api_url1 + '/lesson/params', { "lessonId": lessonId, 'isActive':isActive }, {
            headers: this.standardHeaders()

        })
    }


    updateLesson(data: any) {
        return this.Http.post(environment.api_url1 + '/lesson', data, {
            headers: this.standardHeaders()
        })
    }

    delete(data) {
        return this.Http.post(environment.api_url1 + '/lesson', data, {
            headers: this.standardHeaders()
        })
    }
    getUserLessonsList(organisationId, courseId): Observable<any> {
        return this.Http.post(environment.api_url1
            + '/lessonUser/list?courseId=' + courseId,
            { "organisationId": organisationId, "userId": JSON.parse(localStorage.userObject).userId },
            { headers: this.standardHeaders() });
    }

    pushFileToStorage(file: File, image:File ,lesson: any): Observable<HttpEvent<{}>> {
        let formdata: FormData = new FormData();

        formdata.append('file', file);
        formdata.append('image', image);

        formdata.append('lessonObject', JSON.stringify(lesson));

        const req = new HttpRequest('POST', environment.api_url1 + '/lesson', formdata, {
            reportProgress: true,
            // responseType: 'text'
        });

        return this.Http.request(req);
    }

    getVideoPath(id, user): Observable<any> {
        return this.Http.post(environment.api_url1 
            + '/lesson/launch?lessonId=' + id, user,
            { headers: this.standardHeaders() });
            
    }

    getVideoPathForRecentlyView(id, user , tableName:any ): Observable<any> {
        return this.Http.post(environment.api_url1 
            + '/categoryUser/launch?recordId=' + id +'&tableName='+ tableName, user,
            { headers: this.standardHeaders() });
            
    }

    getAllRecentlyAddedVideos(trnx): Observable<any> {
        return this.Http.post(environment.api_url1
            + '/UserTransaction/list', trnx,
            { headers: this.standardHeaders() });
    }
    getTreeStructureOfLessons(userId: any, organisationId: any) {
        return this.Http.get(environment.api_url1 + '/treeOfLessons/user/' + userId + '/org/' + organisationId);
    }


/// bulk dalete for all lesson component 16:07:18
bulkDeleteLesson(data:any) :Observable<any> {
    return this.Http.post(environment.api_url1+'/lesson/batchDelete',data, {  headers: this.standardHeaders() });
  
  }


}








